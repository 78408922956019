import React from 'react';
import withStore from "@/hocs/withStore";
import Slider from 'react-slick';
import CardAll from "@/Pages/All/CardAll";
import LinkLoad from "@/Layouts/LinkLoad";
import isEmpty from "is-empty";
import Svg from "@/Pages/All/Svg";

const CarouselHit = (props) => {
    const {hitProductData = []} = props;

    if (isEmpty(hitProductData)) {
        return null;
    }

    const settings = {
        autoplay: false,
        autoplaySpeed: 500,
        speed: 500,
        dots: true,
        className: "slick-hit",
        dotsClass: "slick-main-dots",
        arrows: true,
        infinite: true,
        lazyLoad: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                "breakpoint": 1366,
                "settings": {
                    "slidesToShow": 4,
                    "slidesToScroll": 1
                }
            },
            {
                "breakpoint": 1199,
                "settings": {
                    "slidesToShow": 3,
                    "slidesToScroll": 1
                }
            },
            {
                "breakpoint": 992,
                "settings": {
                    "slidesToShow": 3,
                    "slidesToScroll": 1,
                    "arrows": false
                }
            },
            {
                "breakpoint": 767,
                "settings": {
                    "slidesToShow": 2,
                    "slidesToScroll": 1,
                    "arrows": false
                }
            },
            {
                "breakpoint": 320,
                "settings": {
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "arrows": false
                }
            }
        ],
        prevArrow: <AddAiOutlineArrowLeft />,
        nextArrow: <AddAiOutlineArrowRight />,
        appendDots: dots => (
            <ul> {dots} </ul>
        ),
        customPaging: () => (
            <span></span>
        )
    };

    return <div className={`section__hit`}>
        <div className={`container`}>
            <div className={`section__top`}>
                <div className={`section__title`}>
                    <p>Хиты сезона</p>
                </div>
                <LinkLoad href={`/catalog`} className={`section__all`}>
                    Все хиты
                </LinkLoad>
            </div>
            <div className={`slider__hit`}>
                <Slider {...settings}>
                    {hitProductData.map((el) => {
                        return <CardAll key={el.id} el={el} {...props} />
                    })}
                </Slider>
            </div>
        </div>
    </div>
}

const AddAiOutlineArrowLeft = (props) => (
    <Svg id={`arrows-left`} className={`MuiSvgIcon-root arrows arrows__left`} onClick={props.onClick} />
);

const AddAiOutlineArrowRight = (props) => (
    <Svg id={`arrows-right`} className={`MuiSvgIcon-root arrows arrows__right`} onClick={props.onClick} />
);

export default withStore(CarouselHit);
